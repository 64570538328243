import { TOptions } from "i18next";
import { useCallback } from "react";

import initialise, { importTranslations } from "../../shared/translation/initialisation";
import { TranslationKey } from "./resources";
import welsh from "./translations/cy-GB.json";
import english from "./translations/en-GB.json";

const i18next = initialise<`sites:${TranslationKey}`>();

importTranslations("sites", { "en-GB": english, cy: welsh });

export const { LanguageProvider, useLanguage } = i18next;

export function useTranslate() {
  const translate = i18next.useTranslate();
  return useCallback(
    (key: TranslationKey, options?: TOptions | undefined) => translate(`sites:${key}`, options),
    [translate],
  );
}
