import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import { useTranslate } from "../../../../translation/initialisation";
import { OUTER_BAR_COLOUR, PALETTE, TOTAL_COLOUR } from "../../palette";
import {
  ChartLink,
  InDetailChartOptions,
  InDetailData,
  InDetailDataCategory,
  InDetailSegmentDefinition,
} from "../../types";

export const MIN_ROW_HEIGHT = 64;
export const SUBBAR_CORNER_RADIUS = 4;

export default function ChartRow({
  data,
  categoryIndex,
  options,
  link,
}: {
  data: InDetailData;
  categoryIndex: number;
  options: InDetailChartOptions;
  link?: ChartLink | null;
}) {
  const category = data.categories[categoryIndex];

  // We can skip the entire row if there's nothing in it:
  if (!options.showOuterBars && category.segments?.every((segment) => segment.count === 0)) return null;

  return (
    <div className="c-in-detail-bar-chart__row">
      <div className="c-in-detail-bar-chart__row-label">
        {link ? (
          <Link to={link.link} onClick={link.linkOnClick}>
            {category.label}
          </Link>
        ) : (
          category.label
        )}
      </div>
      <div className="c-in-detail-bar-chart__bars-container">
        <div className="c-in-detail-bar-chart__bars">
          <ChartRowBars data={data} categoryIndex={categoryIndex} options={options} />
        </div>
      </div>
    </div>
  );
}

function ChartRowBars({
  data,
  categoryIndex,
  options,
}: {
  data: InDetailData;
  categoryIndex: number;
  options: InDetailChartOptions;
}) {
  const category = data.categories[categoryIndex];

  if (!category.segments) {
    return <Bar data={data} categoryIndex={categoryIndex} options={options} variant="unsplit" />;
  }

  return (
    <>
      {options.showOuterBars ? (
        <Bar categoryIndex={categoryIndex} data={data} options={options} variant="outer" />
      ) : null}
      {category.segments.map((value, j) => (
        <Bar categoryIndex={categoryIndex} data={data} splitIndex={j} options={options} variant="inner" key={j} />
      ))}
    </>
  );
}

export function getBarColour(splitIndex?: number, variant?: "outer" | "inner" | "unsplit") {
  if (variant === "outer") return OUTER_BAR_COLOUR;
  if (variant === "unsplit" || splitIndex === undefined) return TOTAL_COLOUR;
  return PALETTE[splitIndex % PALETTE.length];
}

function Bar({
  data,
  categoryIndex,
  splitIndex,
  options,
  variant,
}: {
  data: InDetailData;
  categoryIndex: number;
  splitIndex?: number;
  options: InDetailChartOptions;
  variant: "outer" | "inner" | "unsplit";
}) {
  const category = data.categories[categoryIndex];
  const value = splitIndex === undefined ? category.count : category.segments![splitIndex].count;

  if (!options.showZeroBars && value === 0) return null;

  const colour = getBarColour(splitIndex, variant);
  const split = splitIndex === undefined ? undefined : data.splits![splitIndex];

  return (
    <>
      <div
        className={`c-in-detail-bar-chart__bar c-in-detail-bar-chart__bar--${variant}`}
        style={{ "--value": value, "--colour": colour } as CSSProperties}
      >
        {variant === "outer" ? null : (
          // Move the tooltip outside the container for the outer bar so it can float above the other bars which float above this one
          <Tooltip category={category} split={split} grandTotal={data.grandTotal} options={options} number={value} />
        )}
        <Value options={options} value={value} grandTotal={data.grandTotal} />
      </div>
      {variant === "outer" ? (
        <Tooltip category={category} grandTotal={data.grandTotal} options={options} number={value} colour={colour} />
      ) : null}
    </>
  );
}

function Tooltip({
  category,
  split,
  number,
  grandTotal,
  options,
  colour,
}: {
  category: InDetailDataCategory;
  split?: InDetailSegmentDefinition;
  number: number;
  grandTotal?: number;
  options: InDetailChartOptions;
  colour?: string;
}) {
  const translate = useTranslate();

  const styles = { "--value": number } as CSSProperties;
  // @ts-ignore - Typescript doesn't know about variables
  if (colour) styles["--colour"] = colour;

  return (
    <div className="c-in-detail-bar-chart__bar-tooltip" style={styles}>
      <div className="c-in-detail-bar-chart__bar-tooltip-body">
        <div className="c-in-detail-bar-chart__bar-tooltip-category">{category.label}</div>
        <div className="c-in-detail-bar-chart__bar-tooltip-segment">{split?.label ?? translate("chart:total")}</div>
        <Value options={options} value={number} grandTotal={grandTotal} force />
      </div>
    </div>
  );
}

export function Value({
  options,
  value,
  grandTotal,
  force,
}: {
  options: InDetailChartOptions;
  value: number;
  grandTotal?: number;
  force?: boolean;
}) {
  if (options.showPercentages && grandTotal !== undefined) {
    const percentage = ((value / grandTotal) * 100).toFixed(1);
    if (options.showNumbers) return <div className="c-in-detail-bar-chart__value">{`${value} (${percentage}%)`}</div>;
    return <div className="c-in-detail-bar-chart__value">{percentage}%</div>;
  }
  if (!options.showNumbers && !force) return null;
  return <div className="c-in-detail-bar-chart__value">{value}</div>;
}
