import React, { ReactNode } from "react";
import slug from "slug";

import useStyle, { Theme, ThemeProvider } from "../components/theme";
import { InDetailChartBlock, OverTimeChartBlock } from "./AnalyticsChartBlock";
import {
  Block,
  BOLD,
  ITALIC,
  List,
  Heading,
  Paragraph,
  RichTextDocument,
  TopLevelNode,
  UNDERLINE,
  Table,
  Image,
  Caption,
} from "./types";

export function headingId(content: string) {
  return slug(content);
}

export default function BlockEditorContent({ content, theme }: { content: RichTextDocument; theme: Theme }) {
  // In principle these functions should never get invalid content, but they ought to be able to handle it anyway — there's no security to stop people injecting invalid content (it's harmless) and if future updates as mishandled, it may result in outdated data structures sitting in the database, so let's be conservative and make sure we fail in a way that's annoying rather than catastrophic.
  if (!content?.root?.children) return null;
  return (
    <ThemeProvider value={theme}>
      <RenderChildren content={content.root.children} />
    </ThemeProvider>
  );
}

export function BlockEditorContentInline({ content }: { content: RichTextDocument }) {
  if (!content?.root?.children) return null;
  return <RenderChildren content={content.root.children} />;
}

export function BlockEditorContents({ content }: { content: RichTextDocument }) {
  if (!content?.root?.children) return null;
  return <RenderChildren content={content.root.children} />;
}

export function BlockEditorJsonContents({ json }: { json: string }) {
  let content: RichTextDocument;
  if (!json) return null;
  try {
    content = JSON.parse(json);
  } catch {
    // NOTE: we log the JSON that failed here, so do not use the Lexical editor for sensitive content, only for presentational stuff.
    console.error("Invalid JSON", json);
    return null;
  }
  return <BlockEditorContents content={content} />;
}

export function ContentHeadings({ content }: { content: RichTextDocument }) {
  if (!content?.root?.children) return null;
  return <RenderHeadings content={content.root.children} />;
}

export function ContentCaption({ caption }: { caption: Caption | null | undefined }) {
  const className = useStyle("caption");
  if (!caption) return null;
  return (
    <div className={className}>
      <BlockEditorJsonContents json={caption.json} />
    </div>
  );
}

function indentStyle({ indent }: TopLevelNode) {
  return { paddingLeft: indent ? `${indent}rem` : undefined };
}

export function BlockEditorHeading({ content, index }: { content: Heading; index: number }) {
  const className = useStyle("Heading");
  if (!content?.children?.[0]?.text) return null;
  const TagName = content.tag;
  const id = content.children.map((text) => text.text);
  return (
    <TagName className={className} style={indentStyle(content)} id={headingId(id.join("") + index)}>
      <RenderChildren content={content.children} />
    </TagName>
  );
}
export function BlockEditorImage({ content }: { content: Image }) {
  const imageBlock = useStyle("imageBlock");
  const image = useStyle("image");
  if (!content?.src) return null;
  return (
    <div className={imageBlock}>
      <img src={content.src} alt={content.altText} className={image} />
      <ContentCaption caption={content.caption} />
    </div>
  );
}

export function ContentBlockHeadings({ content, index }: { content: Heading; index: number }) {
  if (!content?.children?.[0]?.text) return null;
  const id = content.children.map((text) => text.text);
  return (
    <li key={index}>
      <a href={`#${headingId(id.join("") + index)}`} className={`insight_header ${content.tag}`}>
        <RenderChildren content={content.children} />
      </a>
    </li>
  );
}

export function BlockEditorParagraph({ content }: { content: Paragraph }) {
  const className = useStyle("Block");
  if (!content?.children) return null;
  return (
    <p className={className} style={indentStyle(content)}>
      <RenderChildren content={content.children} />
    </p>
  );
}

export function BlockEditorList({ content }: { content: List }) {
  const ListTag = content.listType === "bullet" ? "ul" : "ol";
  const block = useStyle("Block");
  const item = useStyle("ListItem");
  const sublist = useStyle("ListWithSubList");
  if (!content?.children) return null;
  return (
    <ListTag className={block}>
      {content.children.map(({ children, value }, i) => (
        <li
          key={i}
          className={`
            ${item}
            ${children.some((child) => child.type === "list") ? sublist : ""}
          `}
          style={indentStyle(content)}
          value={value}
        >
          <RenderChildren content={children} />
        </li>
      ))}
    </ListTag>
  );
}

export function RenderTable({ content }: { content: Table }) {
  const tableContainer = useStyle("tableContainer");
  const columnHeader = useStyle("columnHeader");

  if (!content?.rows) return null;

  return (
    <div className="tableBlock">
      <div className={tableContainer} tabIndex={0}>
        <table>
          {content.rows.map((r, i) => (
            <tr key={i}>
              {r.cells?.map((c, index) => {
                if (i === 0 && content.rowHeader) {
                  return (
                    <th tabIndex={0} key={index} className={index === 0 && content.columnHeader ? columnHeader : ""}>
                      <BlockEditorJsonContents json={c?.json} />
                    </th>
                  );
                } else if (index === 0 && content.columnHeader) {
                  return (
                    <th tabIndex={0} key={index} className={columnHeader}>
                      <BlockEditorJsonContents json={c?.json} />
                    </th>
                  );
                } else {
                  return (
                    <td tabIndex={0} key={index}>
                      <BlockEditorJsonContents json={c?.json} />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </table>
      </div>
      <ContentCaption caption={content.caption} />
    </div>
  );
}

export function RenderChildren({ content }: { content: Block[] }) {
  return <>{content?.map(renderChild)}</>;
}
export function RenderHeadings({ content }: { content: Block[] }) {
  return <>{content?.map(renderHeadings)}</>;
}

function renderHeadings(block: Block, i: number): ReactNode {
  if (block?.type === "heading") {
    return <ContentBlockHeadings content={block} key={i} index={i} />;
  }
}

// Moving this out to a separate function helps moves the code to the left but mostly works around some minor TypeScript annoyances.
function renderChild(block: Block, i: number): ReactNode {
  switch (block?.type) {
    // Top-level blocks
    case "heading":
      return <BlockEditorHeading content={block} key={i} index={i} />;
    case "paragraph":
      return <BlockEditorParagraph content={block as Paragraph} key={i} />;
    case "list":
      return <BlockEditorList content={block} key={i} />;
    // Text-nodes
    case "link":
      return (
        <a href={block.url} rel={block.rel ?? undefined} target={block.target ?? undefined}>
          <RenderChildren content={block.children} />
        </a>
      );
    // Leaf nodes
    case "text":
      return (
        <span
          key={i}
          style={{
            fontWeight: block.format & BOLD ? "bold" : undefined,
            fontStyle: block.format & ITALIC ? "italic" : undefined,
            textDecoration: block.format & UNDERLINE ? "underline" : undefined,
          }}
        >
          {block.text}
        </span>
      );
    case "linebreak":
      return <br />;
    case "table":
      return <RenderTable content={block} key={i} />;
    case "image":
      return <BlockEditorImage content={block} key={i} />;
    case "cs-analytics-chart--in-detail":
      return <InDetailChartBlock options={block.csConfig} caption={block.caption} />;
    case "cs-analytics-chart--over-time":
      return <OverTimeChartBlock options={block.csConfig} caption={block.caption} />;
  }
}
