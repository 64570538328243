import React from "react";

import { useTranslate } from "../../../translation/initialisation";
import ChartLegend, { LegendTitle } from "../ChartLegend";
import InDetailTable, { GetLinkFunction } from "../InDetailTable";
import { InDetailChartOptions, InDetailData } from "../types";
import useTranslations from "../useTranslations";
import ChartBody from "./ChartBody";

export default function InDetailBarChart({
  data,
  options,
  legendTitle,
  showMultipleAnswerWarning,
  getLink,
}: {
  data: InDetailData;
  options: InDetailChartOptions;
  legendTitle: LegendTitle;
  showMultipleAnswerWarning: boolean;
  getLink?: GetLinkFunction;
}) {
  useTranslations();

  return (
    <>
      <div className={`c-in-detail-bar-chart c-in-detail-bar-chart--${data.splits ? "split" : "unsplit"}`} aria-hidden>
        {showMultipleAnswerWarning && !data.splits ? <MultipleAnswerWarning /> : null}
        <ChartBody data={data} chartOptions={options} getLink={getLink} />
        {data.splits ? (
          <div className="c-in-detail-bar-chart__legend-container">
            <ChartLegend title={legendTitle} splits={data.splits} />
            {showMultipleAnswerWarning ? <MultipleAnswerWarning /> : null}
          </div>
        ) : null}
      </div>
      <InDetailTable data={data} options={options} getLink={getLink} className="is-sr-only" />
    </>
  );
}

function MultipleAnswerWarning() {
  useTranslations();
  const translate = useTranslate();
  return <p className="multiple-answer-warning typography">{translate("chart:multipleAnswersWarning")}</p>;
}
