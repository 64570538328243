import React, { useEffect, useState } from "react";

import { useTranslate } from "../translation/frontend";
import { useForm } from "./context";
import CheckIcon from "./icons/CheckIcon";

const CAPTCHA_ELEMENT_ID = "g-recaptcha-el";

export default function Captcha() {
  const translate = useTranslate();
  const { formId, sessionId, verifiedSession, setVerifiedSession } = useForm();

  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (verifiedSession) return;
    // @ts-ignore — this function won't exist on the server but will on the client:
    window["renderRecaptcha"]?.(CAPTCHA_ELEMENT_ID, async (token: string) => {
      const response = await fetch("/sessions", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ token, formId, sessionId }),
      });
      if (response.status === 200) setVerifiedSession(true);
      else setFailed(true);
    });
  }, [formId, sessionId, verifiedSession, setVerifiedSession]);

  if (verifiedSession) {
    return (
      <div className="field field--isRequired field--isSingle field--captcha field--captcha__complete">
        <div className="options__label">
          <input
            id={CAPTCHA_ELEMENT_ID}
            type="checkbox"
            className="form-control options__check options__checkbox captcha--complete"
            disabled
            checked
          />
          <CheckIcon />
          <div className="options__label-target">
            <label htmlFor={CAPTCHA_ELEMENT_ID}>{translate("captchaPassed")}</label>
          </div>
        </div>
      </div>
    );
  }

  if (failed) {
    return (
      <div className="field field--isRequired field--isSingle field--captcha field--captcha__failed">
        <div id={CAPTCHA_ELEMENT_ID} className="captcha--failed">
          {translate("captchaFailed")} <a href="?">{translate("captchaFailedLink")}</a>
        </div>
      </div>
    );
  }

  return (
    <div className="field field--isRequired field--isSingle field--captcha">
      <div id={CAPTCHA_ELEMENT_ID}>{translate("captchaLoading")}</div>
    </div>
  );
}
