import i18next, {
  FormatFunction,
  i18n,
  InterpolationOptions,
  PostProcessorModule,
  ResourceLanguage,
  TOptions,
} from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { createContext, useCallback, useContext } from "react";

export const DEFAULT_LANGUAGE = "en-GB";

export type TranslateFunction<T extends string> = (key: T, options?: TOptions) => string;

// This string is designed to render acceptably (in English anyway), but it should never appear as it should be filtered out by something like the optional formatting postprocessor. To that end it contains a zero-width non-breaking space so that we can identify it easily and uniquely.
export const NONE_PLACEHOLDER = "(none)";

// This function formats values such as dates and numbers when they appear in translatable strings. It will run by default on any interpolated value (ie, the number in "you have {{count}} items") and the translation key reads "you have {{count, text}} items" then `format` will be set to "text". See https://www.i18next.com/translation-function/formatting for more
const format: FormatFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any, // The value to be formatted
  format?: string, // The string passed in with it (if any)
  lng?: string, // The language we are translating into
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: InterpolationOptions & { [key: string]: any },
) => {
  // Format numbers according to the appropriate in-built locale rules — using digit separators, etc.
  if (typeof value === "number") {
    try {
      return value.toLocaleString(lng);
    } catch (e) {
      // handle case when locale is unsupported by this browser, default to user's locale
      if (e instanceof Error && e.name === "RangeError") {
        return value.toLocaleString();
      }
      throw e;
    }
  }

  // Slight hack here: the optional postprocessor uses | as a special character so let's quietly strip those out if we find them.
  if (typeof value === "string") {
    return value.replace(/\|/g, "");
  }

  // Fallback
  return value?.toString() ?? NONE_PLACEHOLDER;
};

interface Payload<TranslationKey extends string> {
  i18next: i18n;
  initialisation: Promise<unknown>;
  /** @deprecated — use the one from shared/components/translation */
  LanguageProvider: React.Provider<string>;
  /** @deprecated — use the one from shared/components/translation */
  useLanguage: () => string;
  /** @deprecated — use the one from shared/components/translation */
  useTranslate: () => TranslateFunction<TranslationKey>;
}

let initilisedWithPlugins: string[] | null = null;
let initialisedAt: string | null = null;
let initialisedValue: Payload<string> | null = null;

export default function initialise<TranslationKey extends string>(
  ...plugins: PostProcessorModule[]
): Payload<TranslationKey> {
  if (initilisedWithPlugins) {
    if (!arrayEqual(initilisedWithPlugins, names(plugins))) {
      throw new Error(
        "Attempt to reinitialise translation with different plugins." +
          (initialisedAt ? ` First initialised at: ${initialisedAt}\n` : ""),
      );
    }
    return initialisedValue!;
  }

  initilisedWithPlugins = names(plugins);
  initialisedAt = new Error().stack!.replace(/^.*\nError\n +at/m, "");

  i18next.use(intervalPlural);
  for (const plugin of plugins) i18next.use(plugin);

  // Initialisation returns a promise, but we don't need to await it — if initialisation fails the process will shut down, and initialisation is fast enough that it's at least vanishingly unlikely and probably impossible that whatever code has imported this is anywhere near rendering content yet. In the case where we do need to await it (usually tests), the promise is returned.
  const initialisation = i18next
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: { format, alwaysFormat: true },
    })
    .catch(async (error) => {
      console.error("Error during initialisation of i18next", error);
      process?.exit(1);
    });

  const i18nContext = createContext(DEFAULT_LANGUAGE);
  initialisedValue = {
    i18next,
    initialisation,
    LanguageProvider: i18nContext.Provider,
    useLanguage() {
      return useContext(i18nContext);
    },
    useTranslate() {
      const lng = useContext(i18nContext);
      return useCallback<TranslateFunction<string>>((key, options) => i18next.t(key, { lng, ...options }), [lng]);
    },
  };
  return initialisedValue;
}

// Note: we use "cy" here because changing it now would be very hard,
// but "cy-GB" for the JSON files because the third-party translation tool we use requires it.
type LanguageCode = "en-GB" | "cy";

export function importTranslations(namespace: string, translations: Partial<Record<LanguageCode, ResourceLanguage>>) {
  if (!initialisedValue) throw new Error("Translations have not been initialised");
  for (const language in translations) {
    if (initialisedValue.i18next.hasResourceBundle(language, namespace)) continue;
    initialisedValue.i18next.addResourceBundle(language, namespace, translations[language as LanguageCode]);
  }
}

function names(plugins: PostProcessorModule[]) {
  return plugins.map((p) => p.name);
}

function arrayEqual(a: string[], b: string[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) if (a[i] !== b[i]) return false;
  return true;
}

// These are only for use in the shared library where we can't access the local initialised version. They assume initialisation has already happened. They're reasonably safe to use elsewhere but still bad practice.

/** @deprecated — use the one from shared/components/translation */
export function useTranslate() {
  if (!initialisedValue) throw new Error("Translations have not been initialised");
  return initialisedValue.useTranslate();
}

// This is only for use in the shared library where we can't access the local initialised version. It assumes initialisation has already happened. It's reasonably safe to use elsewhere but still bad practice.
export function getI18next() {
  if (!initialisedValue) throw new Error("Translations have not been initialised");
  return initialisedValue.i18next;
}
