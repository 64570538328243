import React, { CSSProperties } from "react";

import { useTranslate } from "../../../translation/initialisation";
import ChartLegend from "../ChartLegend";
import { getBarColour } from "../InDetailBarChart/ChartBody/ChartRow";
import OverTimeTable from "../OverTimeTable";
import { useMonthShortNames, yearLabel } from "../months";
import { OverTimeData } from "../types";
import useTranslations from "../useTranslations";

const NUM_DIVISIONS = 5;
const DIV_PERCENT = 100 / NUM_DIVISIONS;
export const MONTH_INDICES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;

export default function OverTimeBarChart({
  year,
  comparisonYear,
  startMonth,
  data,
}: {
  year: string;
  comparisonYear?: string | null;
  startMonth: string;
  data: OverTimeData;
}) {
  useTranslations();

  const translate = useTranslate();
  const monthName = useMonthShortNames(startMonth);

  const maxValue = Math.max(...data.years.flatMap((year) => year.months.map((month) => month.count)));
  const axisDivision = Math.ceil(Math.max(5, maxValue) / NUM_DIVISIONS);
  const max = axisDivision * NUM_DIVISIONS;
  const axisLabels: number[] = [];
  for (let i = 0; i <= NUM_DIVISIONS; i++) {
    axisLabels.push(i * axisDivision);
  }

  const monthCount = data.years[0]?.months.length ?? 0;

  return (
    <div className="c-over-time-bar-chart-container">
      <div
        className={`c-over-time-bar-chart ${!comparisonYear ? "c-over-time-bar-chart__no-comparison" : ""}`}
        style={{ "--max-value": max } as CSSProperties}
        aria-hidden
      >
        <div className="c-over-time-bar-chart__axis c-over-time-bar-chart__axis--first">
          {axisLabels.map((axisLabel, i) => (
            <div
              key={i}
              className="c-over-time-bar-chart__axis-label"
              style={{ "--fraction": `${i * DIV_PERCENT}%` } as CSSProperties}
            >
              {axisLabel.toString()}
            </div>
          ))}
        </div>
        <div className="c-over-time-bar-chart__body">
          {axisLabels.map((_, i) => (
            <div
              key={i}
              className="c-over-time-bar-chart__gridline"
              style={{ "--fraction": `${i * DIV_PERCENT}%` } as CSSProperties}
            />
          ))}
          {MONTH_INDICES.slice(0, monthCount).map((i) => (
            <div key={i} className="c-over-time-bar-chart__series">
              <div className="c-over-time-bar-chart__series-label">{monthName(i)}</div>
              <div className="c-over-time-bar-chart__series-values">
                <Bar
                  label={yearLabel(year, startMonth, monthCount)}
                  colour={getBarColour(0)}
                  value={data.years[0].months[i].count}
                />
                {comparisonYear ? (
                  <Bar
                    label={yearLabel(comparisonYear, startMonth, monthCount)}
                    colour={getBarColour(1)}
                    value={data.years[1].months[i].count}
                  />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className="c-over-time-bar-chart__axis c-over-time-bar-chart__axis--last">
          {axisLabels.map((axisLabel, i) => (
            <div
              key={i}
              className="c-over-time-bar-chart__axis-label"
              style={{ "--fraction": `${i * DIV_PERCENT}%` } as CSSProperties}
            >
              {axisLabel}
            </div>
          ))}
        </div>
        <div className="c-over-time-bar-chart__axis-name">{translate("chart:numberOfReports")}</div>
      </div>
      <OverTimeLegend year={year} comparisonYear={comparisonYear} month={startMonth} duration={monthCount} />
      <OverTimeTable
        data={data}
        month={startMonth}
        year={year}
        comparisonYear={comparisonYear}
        className="is-sr-only"
      />
    </div>
  );
}

function Bar({ label, colour, value }: { label: string; colour: string; value: number }) {
  return (
    <>
      <div
        className="c-over-time-bar-chart__series-bar"
        style={{ "--colour": colour, "--length": value } as CSSProperties}
      >
        <div className="c-over-time-bar-chart__tooltip">
          <span className="c-over-time-bar-chart__tooltip-color" /> {label}: <strong>{value}</strong>
        </div>
      </div>
    </>
  );
}

export function OverTimeLegend({
  year,
  month,
  comparisonYear,
  duration,
}: {
  year: string;
  month: string;
  comparisonYear?: string | null;
  duration: string | number;
}) {
  const translate = useTranslate();
  if (!comparisonYear) return null;

  const legendSeries = [
    { label: yearLabel(year, month, duration) },
    { label: yearLabel(comparisonYear, month, duration) },
  ];
  return <ChartLegend title={translate("chart:legendTitle")} splits={legendSeries} />;
}
