import React, { FunctionComponent, useCallback, useState } from "react";

import Button from "../../../../shared/components/design-system/Button";
import { useTranslate } from "../../../translation/frontend";
import DisableAccountDialog from "./DisableDialog/DisableAccountDialog";

const SettingsPanel: FunctionComponent<{
  authCode: string;
  deleteAccount: (authCode: string) => Promise<void>;
}> = ({ authCode, deleteAccount }) => {
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <div className="settingsContainer">
      <div className="messaging__container reportMessaging messagingPanel text">
        <div className="ds-h2"> {translate("messagingDisableAccountTitle")}</div>
        {translate("messagingDisableAccountBody")}
        <div className="ds-hstack ds-mt-5 ds-justify-start">
          <Button
            variant="danger"
            size="medium"
            label={translate("messagingDisableAccountButton")}
            submit
            onClick={openModal}
          />
        </div>
      </div>
      <DisableAccountDialog
        modalOpen={modalOpen}
        closeModal={closeModal}
        authCode={authCode}
        deleteAccount={deleteAccount}
      />
    </div>
  );
};

export default SettingsPanel;
