import i18next from "i18next";
import React, { createContext, CSSProperties, PropsWithChildren, ReactNode, useContext, useMemo } from "react";

export const DEFAULT_LANGUAGE = "en-GB";

type TranslationOption = string | number;

export interface FullTranslatable {
  t: string;
  [option: string]: TranslationOption;
}

/** A simple wrapper around a string to say "don't translate this". Passing in a string will assume it's a translation key. */
export interface Untranslatable {
  literal: string;
}

/** This can be: an object with key and options, just the key as a string, or at a pinch, just the untranslatable text as a string */
export type Translatable = string | FullTranslatable | Untranslatable;

const languageContext = createContext(DEFAULT_LANGUAGE);
export function LanguageProvider({ language, children }: PropsWithChildren<{ language: string }>) {
  return <languageContext.Provider value={language}>{children}</languageContext.Provider>;
}

// This function (and everything in this file) assumes that i18next has been initialised correctly with everything you're going to need.
export function useTranslate() {
  const lng = useContext(languageContext);
  // We can't use useCallback here because there are too many signatures to define
  return useMemo(() => {
    /** Translates the string based on the key and the arguments */
    function translate(key: string, args: { [option: string]: TranslationOption }): string;
    /** Translates the string based on the key if it exists */
    function translate(keyOrUntranslatedText: Translatable): string;
    /** Translates the string based on the key if it exists, or returns null if the key was missing */
    function translate(key: Translatable | null | undefined): string | null;
    function translate(options?: Translatable | null | undefined, args: { [option: string]: TranslationOption } = {}) {
      if (options === null || options === undefined) return null;
      // TODO: When we start getting serious about localisation, this should console.warn when it's returning the original string.
      if (typeof options === "string") return i18next.t(options, { ...args, lng }) ?? options;
      if (!("t" in options) && "literal" in options) return options.literal;
      const { t, ...opts } = options;
      return i18next.t(t, { ...opts, ...args, lng }) ?? t;
    }
    return translate;
  }, [lng]);
}

/**
 * <Translation t="someString" param="4" />
 * or if you've got a translatable in a variable then
 * <Translation props={translatable} />
 **/
export function Translation(props: FullTranslatable | { props: Translatable | null | undefined }) {
  const translate = useTranslate();
  return <>{translate("t" in props ? props : props.props)}</>;
}

/** Just a <Translation> wrapped in a <tag>, because it comes up quite a bit. Not you can't use <TextElement tag="p" t="someString" param="4" /> because TypeScript gets upset — use <TextElement tag="p" props={{ t: "someString", param: "4" }} /> instead. */
export function TextElement({
  className,
  style,
  tag: Tag,
  ...props
}: { className?: string; style?: CSSProperties; tag: "p" | "h2" | "h3" | "span" } & (
  | { t: string }
  | { props: Translatable | null | undefined }
)) {
  return (
    <Tag className={className} style={style}>
      <Translation {...props} />
    </Tag>
  );
}

export function isTranslatable(message: Translatable | ReactNode): message is Translatable {
  if (!message) return false;
  if (typeof message === "string") return true;
  // @ts-ignore - this is safe but TypeScript doesn't know that
  if ("t" in message || "literal" in message) return true;
  return false;
}
