import React from "react";

import { useTranslate } from "../translation/frontend";
import { useForm } from "./context";
import ArrowRightIcon from "./icons/ArrowRightIcon";

export function PaginationWide() {
  const translate = useTranslate();
  const { isLastPage, showValidationError, loading, disableNextButton, onBack } = useForm();

  // TODO: show an explanation if the buttons are disabled

  return (
    <section className="section report-paging">
      <div className="container--slim">
        {isLastPage ? (
          <div className="report-paging__submit">
            {showValidationError ? (
              <span className="report-paging__error continue is-invalid" role="alert">
                {translate("pageIncompleteError")}
              </span>
            ) : null}
            <button
              className="btn btn--continue"
              type="submit"
              name="_action"
              value="next"
              disabled={loading || disableNextButton}
            >
              <span>{translate("sendReportLabel")}</span>
              <span className="btn__arrow">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        ) : (
          <div className="report-paging__continue">
            {showValidationError ? (
              <span className="report-paging__error continue is-invalid" role="alert">
                {translate("pageIncompleteError")}
              </span>
            ) : null}
            <button
              className="btn btn--continue btn--swipe btn--arrow"
              type="submit"
              name="_action"
              value="next"
              disabled={loading || disableNextButton}
            >
              <span>{translate("continueLabel")}</span>
              <span className="btn__arrow">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        )}
        {onBack ? (
          <button
            className="btn btn--back btn--arrow"
            name="_action"
            value="back"
            formNoValidate
            onClick={onBack}
            disabled={loading || disableNextButton}
          >
            <span className="btn__arrow">
              <ArrowRightIcon />
            </span>
            <span>{translate("backLabel")}</span>
          </button>
        ) : null}
      </div>
    </section>
  );
}
