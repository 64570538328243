import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import { useTranslate } from "../../../translation/initialisation";
import { ChartLink, InDetailChartOptions, InDetailData } from "../types";
import useTranslations from "../useTranslations";

export type GetLinkFunction = (categoryIndex: number, segmentIndex?: number) => ChartLink | null;

export default function InDetailTable({
  data,
  options,
  getLink,
  className,
}: {
  data: InDetailData;
  options: InDetailChartOptions;
  getLink?: GetLinkFunction;
  className?: string;
}) {
  useTranslations();

  const translate = useTranslate();

  if (!data.splits || data.splits.length < 2) {
    return (
      <table className={className} tabIndex={0}>
        <tbody>
          {data.categories.map((category, i) => (
            <tr key={i}>
              <th tabIndex={0}>{category.label}</th>
              <td tabIndex={0}>
                <LinkedValue
                  link={getLink?.(i)}
                  value={category.count}
                  grandTotal={data.grandTotal}
                  options={options}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const maxValue = Math.max(
    ...data.categories.flatMap((category) => category.segments!.map((segment) => segment.count)),
  );

  return (
    <div className="tableContainer" tabIndex={0}>
      <table className={className}>
        <thead>
          <tr>
            <th className="columnHeader" tabIndex={0}></th>
            {data.splits.map((split, i) => (
              <th key={i} tabIndex={0}>
                {split.label}
              </th>
            ))}
            <th tabIndex={0}>{translate("chart:total")}</th>
          </tr>
        </thead>
        <tbody>
          {data.categories.map((category, i) => (
            <tr key={i}>
              <th className="columnHeader" tabIndex={0}>
                {category.label}
              </th>
              {category.segments!.map((segment, j) => (
                <td
                  key={j}
                  tabIndex={0}
                  style={{ "--analytics-table-heat-map-strength": segment.count / maxValue } as CSSProperties}
                >
                  <LinkedValue
                    link={getLink?.(i, j)}
                    value={segment.count}
                    grandTotal={data.grandTotal}
                    options={options}
                  />
                </td>
              ))}
              <td tabIndex={0}>
                <LinkedValue
                  link={getLink?.(i)}
                  value={category.count}
                  grandTotal={data.grandTotal}
                  options={options}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function LinkedValue({
  value,
  link,
  grandTotal,
  options: { showPercentages },
}: {
  value: number;
  link: ChartLink | null | undefined;
  grandTotal?: number;
  options: InDetailChartOptions;
}) {
  return (
    <>
      {link ? (
        <Link to={link.link} onClick={link.linkOnClick}>
          {value}
        </Link>
      ) : (
        value
      )}
      {showPercentages ? <div>({((value / grandTotal!) * 100).toFixed(1)}%)</div> : null}
    </>
  );
}
