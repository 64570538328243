/// <reference path="report-validity.d.ts"/>
// @ts-ignore - on the server we force this to become @sentry/node which confuses TypeScript
import * as Sentry from "@sentry/browser";
import React, {
  FormEvent,
  MouseEvent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import reportValidity from "report-validity";

import { AnswerTemplate } from "../../db/models/Answer";
import { FormInterstitial, QuestionGroup as FormQuestionGroup } from "../../db/models/Form";
import { parseInputValues } from "../controllers/form/answer-parsing";
import { UploadWarningProps } from "./UploadWarning";
import sendBeacon from "./beacon";
import { nextPage } from "./page-logic";

export type Payload = ReturnType<typeof usePayload>;

interface HistoryPage {
  pageHistory: number[];
}

export interface ReportFormProps {
  formId: string;
  csrfToken: string;
  csrfFailure: boolean;
  pages: (FormQuestionGroup | FormInterstitial)[];
  pageHistory: number[]; // pages are 1-indexed
  answers: Record<string, AnswerTemplate[]>;
  sessionId: string;
  uploadWarning: UploadWarningProps | null;
  ssoProvider: string | null;
}

export type ContextProps = ReportFormProps & {
  formRef: React.RefObject<HTMLFormElement>;
  scrollRef: React.RefObject<HTMLElement>;
};

// @ts-ignore - This will never be used without a provider
const context = createContext<Payload>(null);

export function useForm() {
  return useContext(context);
}

export function FormProvider({ children, ...props }: PropsWithChildren<ContextProps>) {
  const payload = usePayload(props);
  return <context.Provider value={payload}>{children}</context.Provider>;
}

export function usePayload({
  pages,
  pageHistory: originalPageHistory,
  formId,
  csrfToken,
  csrfFailure,
  sessionId,
  uploadWarning,
  ssoProvider,
  scrollRef,
  formRef,
  ...props
}: ContextProps) {
  const [answers, setAnswers] = useState(props.answers);
  const [showValidationError, setShowValidationError] = useState(false);
  const [fullPageHistory, setFullPageHistory] = useState(originalPageHistory);
  const currentPage = fullPageHistory[0];
  const canGoBack = fullPageHistory.length > originalPageHistory.length;
  const page = pages[currentPage - 1];
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pages.length; // The last page cannot be conditional so we don't have to check if there are hidden pages past this point.
  const [loading, setLoading] = useState(false);
  const [hideWarnings, setHideWarnings] = useState(false);

  const formHasCaptcha = pages.some((page) => page.type === "INTERSTITIAL" && page.hasCaptcha);
  const formHasLoginGate = pages.some((page) => page.type === "INTERSTITIAL" && page.hasLoginGate);
  const isFirstRender = useIsFirstRender();
  const [verifiedSession, setVerifiedSession] = useState(false);
  const disableNextButton = (formHasLoginGate || formHasCaptcha) && isFirstRender;

  useEffect(() => {
    Sentry.addBreadcrumb({ category: "reportFormLoaded", data: { pageHistory: originalPageHistory } });
  }, [originalPageHistory]);

  // Don't set fullPageHistory directly, use this wrapped function which also updates the hit counter etc
  const changePage = useCallback(
    async (page: HistoryPage | undefined, focusQuestion?: string) => {
      Sentry.addBreadcrumb({ category: "changePage", data: page });
      setShowValidationError(false);
      const pageHistory = page?.pageHistory ?? originalPageHistory;
      sendBeacon("FORM", formId, pageHistory[0], currentPage, sessionId);
      setFullPageHistory(pageHistory);
      if (focusQuestion) {
        setTimeout(() => {
          document.getElementById(`${focusQuestion}-container`)?.scrollIntoView();
        }, 0);
      } else {
        if (scrollRef.current) scrollRef.current.scrollIntoView();
        else window.scrollTo(0, 0);
      }
      setHideWarnings(true);
    },
    [currentPage, formId, originalPageHistory, sessionId, scrollRef],
  );

  // Don't call the changePage wrapper directly either, use either this function to go forwards, or history.back() to go back:
  const advanceToPage = useCallback(
    async (pageNumber: number) => {
      Sentry.addBreadcrumb({ category: "advancePage", data: { pageNumber } });
      const page: HistoryPage = { pageHistory: [pageNumber, ...fullPageHistory] };
      window.history.pushState(page, "", `#step-${pageNumber}`);
      await changePage(page);
    },
    [changePage, fullPageHistory],
  );

  useEffect(() => {
    const onPopState = (ev: PopStateEvent) => changePage(ev.state);
    window.addEventListener("popstate", onPopState);
    return () => window.removeEventListener("popstate", onPopState);
  }, [changePage, fullPageHistory]);

  // This combines the current answers with the answers from previous pages and returns a big "answers" object. It has to be async to handle file inputs.
  const updateAnswersFromForm = useCallback(async () => {
    if (page.type !== "QUESTION_GROUP") return answers;
    const elements = Array.from(formRef.current!.elements) as (HTMLInputElement | HTMLTextAreaElement)[];
    // Get an element containing the value of every input in the form — including all the question inputs, but also the hidden inputs for "_crsf", "previous-answers", etc
    const inputValues: Record<string, Array<string | Promise<string>>> = {};
    for (const element of elements) {
      if (!element?.name || element.type === "button") continue;
      const { name, type, value, files, checked } = element as HTMLInputElement;
      if ((type === "checkbox" || type === "radio") && !checked) continue;
      if (!inputValues[name]) inputValues[name] = [];
      if (files) {
        for (const file of files) {
          // fileToBase64(file) returns a promise but for speed we just collect all the promises here and then resolve them in parseInputValues
          inputValues[name].push(fileToBase64(file));
        }
      } else {
        inputValues[name].push(value);
      }
    }
    return await parseInputValues(page.questions, inputValues);
  }, [answers, formRef, page]);

  // This can be:
  //   • undefined — do not show a "back" button
  //   • a no-op function — show a "back" button but allow it to submit the form as normal
  //   • an active function — show a "back" button and trigger history.back() on click
  const onBack = useMemo(
    () =>
      isFirstPage
        ? undefined
        : (ev: MouseEvent<HTMLButtonElement>) => {
            Sentry.addBreadcrumb({ category: "clickedBack", data: { canGoBack } });
            if (canGoBack) {
              ev.preventDefault();
              window.history.back();
            } else {
              // @ts-ignore — This is a hack to prevent the form's onSubmit handler from firing if you click "back" but we can't handle it client-side. This only happens if JS loads partway through a form (say because of a flaky connection). A better solution would be to use SubmitEvent.submitter, but that's not supported by IE or TypeScript, so that solution will have to wait.
              window.formEventHandled = true;
            }
          },
    [canGoBack, isFirstPage],
  );

  const onNext = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      // @ts-ignore (see onBack for explanation)
      if (window.formEventHandled) {
        // This form submission was triggered by the "back" or "submit now" button so we shouldn't handle it here.
        return;
      }
      if (isLastPage && !loading) {
        // On the last page, we should let the browser's in built behaviour submit the form.
        // Don't call ev.preventDefault().
        // Set this state to true to display the "submitting" spinner,
        // which does nothing except disable the buttons and make the user feel like something is happening.
        setLoading(true);
        return;
      }
      ev.preventDefault();
      if (loading) {
        return;
      }
      const form = ev.currentTarget;
      const requiredChoiceInputsOnThisPage = (page.type === "QUESTION_GROUP" ? page.questions : []).filter(
        (question) => question.required && (question.questionType === "RADIO" || question.questionType === "CHECKLIST"),
      );
      const answers = await updateAnswersFromForm();

      if (
        reportValidity(form) &&
        requiredChoiceInputsOnThisPage.every((question) => question.id in answers && answers[question.id].length > 0)
      ) {
        setAnswers(answers);
        await advanceToPage(nextPage(currentPage, pages, answers));
      } else {
        setShowValidationError(true);
        window.scrollTo(0, 0);
      }
    },
    [isLastPage, loading, page, updateAnswersFromForm, currentPage, pages, advanceToPage],
  );

  // Used to skip between pages (E.g., for moving from the 'review answers' page to an earlier page)
  const onChangePage = useCallback(
    async (pageNumber: number, focusQuestion?: string) => {
      const page: HistoryPage = {
        pageHistory: fullPageHistory.filter((historicalPage) => historicalPage <= pageNumber),
      };
      window.history.pushState(page, "", `#step-${pageNumber}`);
      await changePage(page, focusQuestion);
    },
    [changePage, fullPageHistory],
  );

  return {
    // Information about the form structure:
    formId,
    pages,
    formRef,
    // Current page
    fullPageHistory,
    currentPage,
    page,
    isLastPage,
    // Other user state
    csrfToken,
    sessionId,
    answers,
    // Warnings and errors, etc
    hideWarnings,
    uploadWarning,
    csrfFailure,
    loading,
    showValidationError,
    disableNextButton,
    // Functions to interact with the form
    onNext,
    onBack,
    advanceToPage,
    onChangePage,
    // Captcha and login stuff
    verifiedSession,
    setVerifiedSession,
    ssoProvider,
  };
}

function fileToBase64(file: File): Promise<string> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
  });
}

function useIsFirstRender() {
  const [isFirstRender, update] = useReducer(() => false, true);
  if (isFirstRender) update();
  return isFirstRender;
}
