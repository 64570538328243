import React from "react";

import { useForm } from "./context";
import CheckIcon from "./icons/CheckIcon";

interface ProgressProps {
  scrollRef: React.Ref<HTMLElement>;
}

export default function Progress({ scrollRef }: ProgressProps) {
  const { currentPage, pages } = useForm();
  const totalPages = pages.length;
  return (
    <section className="progress" ref={scrollRef}>
      <span className="is-sr-only" role="status">
        Page {currentPage} of {totalPages}
      </span>
      <div className="container--slim" aria-hidden>
        <div className="steps">
          <div className="steps__progress" style={{ width: `${100 * ((currentPage - 1) / totalPages)}%` }}></div>
          <div className="steps__line"></div>

          <ul className="steps__list">
            {Array.from(Array(totalPages + 1)).map((_, i) => (
              <li key={i} className={i + 1 === currentPage ? "current" : ""}>
                <span className="steps__number">{i + 1}</span>
                <span className="steps__tick">
                  <CheckIcon />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
